@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap);
body {
  background: #0D0D0D !important;
  color: #D3D3D3 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{text-align:center}.App-title{font-family:'Titillium Web', sans-serif;text-align:center}.App-box{border:1px solid #434343;border-radius:6px;padding:15px}.App-box-helper{font-family:'Titillium Web', sans-serif;font-weight:bold;font-size:18px;text-align:center}.company-logo{width:180px;height:16.17px}.Aligner{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;min-height:100vh}.Aligner-item{max-width:50%}.Aligner-item--top{-webkit-align-self:flex-start;align-self:flex-start}.Aligner-item--bottom{-webkit-align-self:flex-end;align-self:flex-end}.input-dark{background-color:#141414 !important;font-size:20px;color:#a2a2a2}.form-group{font-family:'Inter', sans-serif;font-size:16px}.btn-1{font-family:'Inter', sans-serif;font-size:16px;color:#fff;background-color:#294ABA;border-color:rgba(27,31,35,0.15);box-shadow:0 1px 0 rgba(27,31,35,0.1),inset 0 1px 0 rgba(255,255,255,0.03);padding:10px 16px !important}.btn-1:hover{background-color:#2b50ca}@media (prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}

.signin .error{color:#a93434;display:block;font-size:12px;font-weight:600}

.portal{max-width:768px;margin:0 auto;padding:20px}.portal .header{font-family:'Titillium Web', sans-serif;border-bottom:1px solid #eee;position:relative;padding-bottom:15px;width:100%}.portal .header-btn{border-radius:6px;border:1px solid #D9E0E6;background-color:transparent !important;color:#D3D3D3;font-weight:bold;padding-left:25px;padding-right:25px}.portal .exit{margin-bottom:2px}.portal-btn{font-family:'Titillium Web', sans-serif;font-weight:bold;color:#D3D3D3 !important;background-color:transparent !important;border-color:#D9E0E6 !important;width:110px}.portal-btn.active{color:#3a75ff !important}.portal-btn:hover{color:#4C82FC !important;background-color:#181818 !important;border-color:#D9E0E6 !important}.portal .user-info{font-family:'Titillium Web', sans-serif;font-weight:bold;padding:20px 0}.portal .form-select{background-color:transparent !important;border:none;box-shadow:none;color:#fff;vertical-align:top;padding-top:2px;height:auto !important;text-transform:capitalize}.portal table{table-layout:auto;width:100%;border-collapse:separate;border-spacing:0px;font-size:14px;margin:0;padding:0;width:100%}.portal table th{font-family:'Titillium Web', sans-serif;text-align:left;font-size:1rem;padding:20px 10px;color:#D3D3D3}.portal table th.th-doc{white-space:nowrap}.portal table th.th-date{text-align:right}.portal table th.th-price{text-align:right}.portal table th.th-download{text-align:right}.portal table tbody{font-family:'Fira Sans', sans-serif}.portal table tbody tr:nth-child(odd){background-color:#121212}.portal table td{padding:20px 10px}.portal table td.td-date{text-align:right}.portal table td.td-price{text-align:right}.portal table td.td-download{cursor:pointer;text-align:right}

