.portal {
    max-width: 768px;
    margin: 0 auto;
    padding: 20px;
    .header {
        font-family: 'Titillium Web', sans-serif;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-bottom: 15px;
        width: 100%;
        &-btn {
            border-radius: 6px;
            border: 1px solid #D9E0E6;
            background-color: transparent !important;
            color: #D3D3D3;
            font-weight: bold;
            padding-left: 25px;
            padding-right: 25px;
        }
    }
    .exit {
        margin-bottom: 2px;
    }
    &-btn {
        font-family: 'Titillium Web', sans-serif;
        font-weight: bold;
        color: #D3D3D3 !important;
        background-color: transparent !important;
        border-color: #D9E0E6 !important;
        width: 110px;
        &.active {
            color: #3a75ff !important;
        }
    }
    &-btn:hover {
        color: #4C82FC !important;
        background-color: #181818 !important;
        border-color: #D9E0E6 !important;
    }
    .user-info {
        font-family: 'Titillium Web', sans-serif;
        font-weight: bold;
        padding: 20px 0;
    }
    .form-select {
        background-color: transparent !important;
        border: none;
        box-shadow: none;
        color: #fff;
        vertical-align: top;
        padding-top: 2px;
        height: auto !important;
        text-transform: capitalize;
    }
    table {
        table-layout: auto;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px;
        font-size: 14px;
        margin: 0;
        padding: 0;
        width: 100%;
        th {
            font-family: 'Titillium Web', sans-serif;
            text-align: left;
            font-size: 1rem;
            padding: 20px 10px;
            color: #D3D3D3;
            &.th-doc {
                white-space: nowrap;
            }
            &.th-date {
                text-align: right;
            }
            &.th-price {
                text-align: right;
            }
            &.th-download {
                text-align: right;
            }
        }
        tr {
        }
        tbody {
            font-family: 'Fira Sans', sans-serif;
            tr:nth-child(odd) {
                background-color: #121212;
            }
        }
        td {
            padding: 20px 10px;
            &:hover {
            }
            &.td-doc {
            }
            &.td-date {
                text-align: right;
            }
            &.td-price {
                text-align: right;
            }
            &.td-download {
                cursor: pointer;
                text-align: right;
            }
        }
    }
}