// @import "@primer/css/index.scss";
// @import '@primer/css/core/index.scss';
// @import '@primer/css/product/index.scss';
// @import '@primer/css/marketing/index.scss';

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap');

.App {
  text-align: center;
  &-title {
    font-family: 'Titillium Web', sans-serif;
    text-align: center;
  }
  &-box {
    border: 1px solid #434343;
    border-radius: 6px;
    padding: 15px;
    &-helper {
      font-family: 'Titillium Web', sans-serif;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
    }
  }
}
.company-logo {
  width: 180px;
  height: 16.17px;
}
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  &-item {
    max-width: 50%;
  }
  
  &-item--top {
    align-self: flex-start;
  }
  
  &-item--bottom {
    align-self: flex-end;
  }
}
.input-dark {
  background-color: #141414 !important;
  font-size: 20px;
  color: #a2a2a2;
}

.form-group {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}

.btn-1 {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #fff;
  background-color: #294ABA;
  border-color: rgba(27, 31, 35, 0.15);
  box-shadow: 0 1px 0 rgba(27, 31, 35, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.03);
  padding: 10px 16px !important;
  &:hover {
    background-color: #2b50ca;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
